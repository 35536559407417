"use client"; // Error components must be Client Components

import { useCallback, useEffect } from "react";
import type { Route } from "next";
import { usePathname, useSearchParams } from "next/navigation";
import { ApolloError } from "@apollo/client";
import { updateAccessToken, updateRefreshToken } from "~/apis/apolloClient";
import { useToast } from "~/src/hooks/useToast";

type Props = {
  error: Error | ApolloError;
};

export default function Error({ error }: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const toast = useToast();

  const redirectToSignin = useCallback(() => {
    const current = `${pathname}?${searchParams.toString()}`;
    const params = new URLSearchParams({ redirect: current });
    const replaceTo: Route<"/signin"> = `/signin?${params.toString()}`;
    window.location.replace(replaceTo);
  }, [pathname, searchParams]);

  useEffect(() => {
    console.error(error);

    const isApolloError = error instanceof ApolloError;
    const isUnauthorized =
      isApolloError && error.graphQLErrors.at(0)?.message === "Unauthorized";
    if (isUnauthorized) {
      updateAccessToken(null);
      updateRefreshToken(null);
      redirectToSignin();
    } else {
      toast({
        title: "データの取得に失敗しました",
        status: "error",
      });
    }
  }, [error, redirectToSignin, toast]);

  return null;
}
